import { Details, Summary } from '@troon/ui';
import { For, Suspense } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { NoHydration } from 'solid-js/web';
import { createContentfulRequest } from '../routes/api/content/[model]/_client';
import { RichText } from './rich-text';

type Props = {
	id: string;
};

export function FrequentlyAskedQuestions(props: Props) {
	const faqs = createAsync(async () => request(props.id), { deferStream: true });

	return (
		<NoHydration>
			<Suspense>
				<ul class="divide-y divide-neutral-500">
					<For each={faqs()?.fields.faq}>
						{(faq) => (
							<li>
								<Details>
									<Summary class="px-4 py-6 group-open:mb-0 group-open:pb-4">{faq?.fields.question}</Summary>
									<div class="px-4 pb-4">
										<RichText document={faq!.fields.answer} />
									</div>
								</Details>
							</li>
						)}
					</For>
				</ul>
			</Suspense>
		</NoHydration>
	);
}

const request = createContentfulRequest('faqs');
